<template>
  <div class='ticket-date-picker'>
    <div class="action-row">
      <span class="picked-date">{{ pickedDate }}</span>
      <button-base :text="$t('pages.tickets.datePicker.modifyBtn')" @clicked="handleBtnClick" />
    </div>
    <transition
      mode="in-out"
      enter-active-class="animate__animated animate__fadeIn animate__faster"
      leave-active-class="animate__animated animate__fadeOut animate__faster"
    >
      <base-date-picker
        v-if="isDatePickerVisible && dateRange"
        :defaultValue="new Date(selectedDateFromStore)"
        :dateRange="dateRange"
        @picked="handleDateSelection"
        @clickedAway="closeDatePicker" />
    </transition>
  </div>
</template>

<script>
import store from '@/store';
import { mapActions, mapGetters } from 'vuex';

import BaseDatePicker from '@/components/shared/elements/inputs/BaseDatePicker';
import ButtonBase from '@/components/shared/elements/buttons/ButtonBase';

export default {
  name: '',
  props: {},
  components: {
    ButtonBase,
    BaseDatePicker,
  },
  data: () => ({
    isDatePickerVisible: false,
    dateRange: undefined,
  }),
  created() {
    this.getPurchaseDateRange().then((resp) => {
      this.dateRange = resp.data;
    });
  },
  computed: {
    selectedDateFromStore() {
      return store.getters.getSelectedDate;
    },
    pickedDate() {
      return this.selectedDateFromStore === undefined
        ? this.$t('pages.tickets.datePicker.noDateTitle')
        : this.selectedDateFromStore;
    },
  },
  methods: {
    ...mapActions({
      saveSelectedDate: 'cart/saveSelectedDate',
      getPurchaseDateRange: 'settings/getPurchaseDateRange',
    }),
    handleDateSelection(val) {
      this.saveSelectedDate(val);
      this.isDatePickerVisible = false;
    },
    handleBtnClick() {
      this.isDatePickerVisible = true;
    },
    closeDatePicker() {
      this.isDatePickerVisible = false;
    },
  },
};
</script>

<style lang='scss' scoped>
$actionRowWidth: 435px;

.ticket-date-picker {
  display: inline-block;
  position: relative;

  @media screen and (max-width: $breakpointDownXs) {
    width: 100%;
  }
}

.action-row {
  border-radius: $globalBorderRadius;
  background-color: $white;
  box-shadow: 0 5px 15px rgba(#000, 0.16);
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  column-gap: 37px;
  align-items: center;
  width: $actionRowWidth;
  padding: 18px 0;

  @media screen and (max-width: $breakpointDownXs) {
    width: 100%;
    box-shadow: none;
    padding: 15px 0;
    column-gap: 25px;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    grid-template-columns: 0.7fr 1fr;
    column-gap: 10px;
  }

  button {
    padding: 0 34px;
    margin-right: auto;
    margin-left: 0;

    @media screen and (max-width: $breakpointDownXs) {
      padding: 0 25px;
    }

    @media screen and (max-width: $breakpointDownXXs) {
      padding: 0;
      margin-right: 0;
    }
  }
}

.picked-date {
  color: $primaryBlue;
  font-weight: 700;
  font-size: 20px;
  text-align: right;

  @media screen and (max-width: $breakpointDownXXs) {
    font-size: 16px;
  }
}

.base-date-picker {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: $globalBorderRadius;
  box-shadow: $lightDropdownShadow;
  padding: 0;
  width: 80%;
  padding: 30px 35px;

  @media screen and (max-width: $breakpointDownXXs) {
    padding: 20px;
    width: 100%;
  }
}

</style>
