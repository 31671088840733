<template>
  <div class='ticket-row' :class="{ 'secondary-background': hasSecondaryBackground }">
    <div class="info-col">
      <span v-if="tooltipContent" class="ticket-tooltip" v-html="tooltipContent" />
      <span class="info-icon" :class="{ 'no-tooltip': !ticket.metadata }">
        <font-awesome-icon :icon="['fa', 'info-circle']"  />
      </span>
    </div>
    <div class="name-col">
      {{ ticket.cikk_nev }}
    </div>
    <div class="quantity-input-col">
      <base-quantity-input
        :defaultValue="quantityNumber"
        @changed="handleQuantityInput"
      />
    </div>
    <div v-if="isDateSelected" class="price-col">{{ ticket.cikkar.maxPrice | toCurrency }}</div>
    <div v-else class="price-col">{{ priceText }}</div>
    <div v-if="!isCartBtnDisabled" class="cart-btn-col">
      <button-add-to-cart
        :text="$t('pages.tickets.cartButton.text')"
        :isLoading="isBtnLoading"
        :isDisabled="!isDateSelected"
        @clicked="handleCartBtnClick"
      />
    </div>
  </div>
</template>

<script>
import ButtonAddToCart from '@/components/shared/elements/buttons/ButtonAddToCart';
import BaseQuantityInput from '@/components/shared/elements/inputs/BaseQuantityInput';

const ProductNumberAlertModal = () => import('@/components/shared/modules/modals/ProductNumberAlertModal');

export default {
  name: 'TicketRow',
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    isDateSelected: {
      type: Boolean,
      required: true,
    },
    hasSecondaryBackground: {
      type: Boolean,
      required: false,
    },
    isBtnLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCartBtnDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ButtonAddToCart,
    BaseQuantityInput,
  },
  data: () => ({
    quantityNumber: 0,
  }),
  created() {},
  computed: {
    currencyName() {
      return this.$t('general.prices.currency.postfix');
    },
    priceText() {
      return `${this.$options.filters.toCurrencyWithoutSymbol(this.ticket.cikkar.minPrice)} - ${this.$options.filters.toCurrencyWithoutSymbol(this.ticket.cikkar.maxPrice)} ${this.currencyName}`;
    },
    tooltipContent() {
      let result;
      if (this.ticket.metadata) {
        if (this.ticket.metadata.description && this.ticket.metadata.externalLink) {
          result = `${this.ticket.metadata.description} <br><a target='_blank' href="${this.ticket.metadata.externalLink}">${this.ticket.metadata.externalLink}</a>`;
        } else if (this.ticket.metadata.description) {
          result = this.ticket.metadata.description;
        }
      }

      return result;
    },
  },
  methods: {
    showAlertModal() {
      this.$eventBus.showModal({
        bind: {
          is: ProductNumberAlertModal,
          titleText: this.$t('general.alerts.productNumber.title'),
          descriptionText: this.$t('general.alerts.productNumber.description'),
          primaryBtnText: this.$t('general.alerts.productNumber.btnText'),
          modalTitleStyle: { margin: '20px 0 0 0' },
          closeable: true,
        },
        on: {
          primaryButtonClicked: () => {
            this.$eventBus.closeModal();
          },
        },
      });
    },
    handleCartBtnClick() {
      if (this.quantityNumber === 0) {
        this.showAlertModal();
      }

      if (this.quantityNumber > 0) {
        this.$emit('addToCartBtnClicked', this.quantityNumber);
      }
    },
    handleQuantityInput(val) {
      this.quantityNumber = val;
      if (this.isCartBtnDisabled) {
        this.$emit('quantityChanged', val);
      }
    },
    handleTooltipHover(key) {
      switch (key) {
        case 'over':
          this.isTooltipVisible = true;
          break;
        case 'leave':
          this.isTooltipVisible = false;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@keyframes fadeInShow {
  0% {
    display: block;
  }
  100% {
    opacity: 1;
  }
}

.ticket-row {
  display: grid;
  grid-template-columns: auto 1fr 1fr 0.8fr auto;
  background-color: $white;
  padding: 18px 30px;
  align-items: center;
  font-weight: 500;
  font-size: 15px;

  @media screen and (max-width: $breakpointDownLg) {
    grid-template-columns: auto 1fr 0.7fr 0.8fr auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    grid-template-columns: auto 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    padding-left: 0;
    padding-right: 0;
  }

  &.secondary-background {
    background-color: $tableSecondaryBackground;

    @media screen and (max-width: $breakpointDownXs) {
      background-color: transparent;
    }
  }
}

.info-col {
  padding-right: 15px;
  position: relative;
  padding-top: 20px;
  margin-top: -20px;

  @media screen and (max-width: $breakpointDownSm) {
    grid-area: 1 / 1 / 2 / 2;
  }

  .info-icon {
    opacity: 1;
    cursor: pointer;
    position: relative;
    display: block;
    transition: $transitionBase;

    &::v-deep {
      svg {
        background-color: $white;
        border-radius: 100%;
      }

      svg path {
        fill: $primaryBlue;
      }
    }

    &.no-tooltip {
      &:hover {
        opacity: 1;
      }
    }

    &:hover {
      opacity: 0.9;
    }
  }

  &:hover {
    .ticket-tooltip {
      display: block;
      animation: fadeInShow 0.4s forwards;
    }
  }
}

.ticket-tooltip {
  position: absolute;
  bottom: 30px;
  left: -20px;
  z-index: 1;
  background-color: $white;
  color: $globalFontColor;
  min-width: 350px;
  max-width: 550px;
  border-radius: $globalBorderRadius;
  font-size: 12px;
  line-height: 1.4;
  padding: 10px 30px;
  text-align: center;
  height: auto;
  display: inline-block;
  box-shadow: $cartItemRowShadow;
  display: none;
  opacity: 0;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
    position: absolute;
    bottom: -10px;
    left: 17px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    left: -10px;
    transform: none;

    &:after {
      left: 8px;
    }
  }

  @media screen and (max-width: $breakpointDownXs) {
    max-width: 100%;
    min-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.name-col {
  @media screen and (max-width: $breakpointDownSm) {
    grid-area: 1 / 2 / 2 / 3;
    font-size: 16px;
    line-height: 1.2;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    font-size: 15px;
  }
}

.price-col {
  color: $primaryBlue;
  font-weight: 700;

  @media screen and (max-width: $breakpointDownSm) {
    grid-area: 1 / 3 / 1 / 3;
    text-align: right;
    font-size: 16px;
  }
}

.cart-btn-col {
  @media screen and (max-width: $breakpointDownSm) {
    grid-area: 2 / 3 / 3 / 4;
    margin-left: auto;
  }

  button {
    @media screen and (max-width: $breakpointDownSm) {
      font-size: 14px;
      height: 34px;
      min-width: 110px;
    }
  }
}

.quantity-input-col {
  text-align: right;
  padding-right: 60px;

  @media screen and (max-width: $breakpointDownLg) {
    padding-right: 30px;
  }

  &::v-deep {
    .base-quantity-input {
      @media screen and (max-width: $breakpointDownSm) {
          width: auto;
        }
      }
  }

  @media screen and (max-width: $breakpointDownSm) {
    grid-area: 2 / 1 / 3 / 3;
    padding-right: 0;
    margin-right: auto;
  }
}
</style>
