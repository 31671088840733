<template>
  <button class='button-add-to-cart' :class="[{ 'disabled': isDisabled }, { 'loading': isLoading }]"
    @click="handleButtonClick">
    <span v-if="!isLoading" class="text">{{ text }}</span>
    <span v-else class="loader"><font-awesome-icon :icon="['fa', 'spinner']"  /></span>
  </button>
</template>

<script>
export default {
  name: 'ButtonAddToCart',
  props: {
    text: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {
    handleButtonClick() {
      if (!this.isLoading && !this.isDisabled) {
        this.$emit('clicked');
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@keyframes loaderAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

button {
  background-color: $buttonAddToCartBackground;
  color: $white;
  font-size: 12px;
  font-weight: 700;
  border-radius: $globalBorderRadius;
  height: 25px;
  border: 0;
  padding: 0 14px;
  min-width: 85px;

  &:hover {
    background-color: $primaryBlue;
  }

  &.disabled {
    cursor: default;
    background-color: $buttonAddToCartDisabledBackground;
  }

  &.loading {
    &:hover {
      background-color: $buttonAddToCartBackground;
      cursor: default;
    }
  }
}

.loader {
  display: inline-block;
  animation: loaderAnimation 1.6s infinite;
}
</style>
