<template>
  <div class='base-date-picker' v-on-clickaway="handleClickAway">
    <date-picker
      ref="datePicker"
      v-model="date"
      :format="format"
      :default-value="defaultValue"
      :type="'date'"
      :inline="true"
      :title-format="'YYYY-MM-DD'"
      :lang="lang"
      :disabled-date="notBeforeToday"
      prefix-class="xmx"
      @change="handleDatePick"
      :key="lang"
    />
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import 'vue2-datepicker/locale/hu';
import 'vue2-datepicker/locale/de';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/pl';
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/locale/ro';
import 'vue2-datepicker/locale/uk';
import 'vue2-datepicker/locale/cs';
import 'vue2-datepicker/locale/sl';

export default {
  name: 'BaseDatePicker',
  mixins: [clickaway],
  props: {
    defaultValue: {
      type: Date,
      required: false,
    },
    dateRange: {
      type: Object,
      required: false,
    },
  },
  components: {
    DatePicker,
  },
  data: () => ({
    date: undefined,
    lang: 'hu',
    format: 'YYYY-MM-DD',
  }),
  created() {
    if (this.defaultValue) {
      this.date = this.defaultValue;
    }

    this.lang = this.$i18n.locale;
  },
  computed: {
    selectedLangKey() {
      return this.$i18n.locale;
    },
  },
  watch: {
    selectedLangKey(val) {
      switch (val) {
        case 'hu':
          this.lang = 'hu';
          break;
        case 'de':
          this.lang = 'de';
          break;
        case 'en':
          this.lang = 'en';
          break;
        case 'pl':
          this.lang = 'pl';
          break;
        case 'ru':
          this.lang = 'ru';
          break;
        case 'ro':
          this.lang = 'ro';
          break;
        case 'ua':
          this.lang = 'uk';
          break;
        case 'cz':
          this.lang = 'cs';
          break;
        case 'sk':
          this.lang = 'sl';
          break;
        default:
          this.lang = 'hu';
          break;
      }
    },
  },
  methods: {
    notBeforeToday(date) {
      if (this.dateRange) {
        return !this.$moment(date).isSameOrAfter(this.dateRange.from) || !this.$moment(date).isSameOrBefore(this.dateRange.to);
      }

      const today = new Date();
      return date < today.setDate(today.getDate() - 1);
    },
    handleDatePick(date) {
      this.$emit('picked', this.$moment(date).format('YYYY-MM-DD'));
    },
    handleClickAway() {
      this.$emit('clickedAway');
    },
  },
};
</script>

<style lang='scss' scoped>
.base-date-picker {
  padding: 15px;
  background-color: $white;

  &::v-deep {
    .xmx-btn-current-year {
      pointer-events: none;
    }

    .xmx-datepicker-main  {
      font-family: $primaryFontFamily;
    }

    .xmx-calendar {
      width: 100%;
      padding: 0;
    }

    .xmx-btn:hover {
      color: $primaryBlue;
    }

    .xmx-table {
      font-weight: 500;
    }

    .xmx-calendar-header {
      height: auto;
      line-height: 28px;
      margin: 0 0 20px;
    }

    .xmx-btn-icon-double-right,
    .xmx-btn-icon-double-left {
      display: none;
    }

    .xmx-calendar-content .cell.active {
      border-radius: 100%;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2px auto 0;
    }

    .xmx-calendar-panel-month {
      .xmx-calendar-header-label button {
        pointer-events: none;
      }

      .xmx-calendar-content .cell.active  {
        display: table-cell;
        width: auto;
        height: auto;
        background: transparent;
        border-radius: 0;
        margin: 0;

        & > div {
          background-color: $primaryBlue;
          border-radius: $globalBorderRadius;
          padding: 8px 0;
        }
      }
    }

    .xmx-btn-icon-right,
    .xmx-btn-icon-left {
      width: 28px;
      height: 28px;
      line-height: 28px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 1px 1px rgba(#000, 0.16);
      padding: 0;

      i:before {
        border-width: 3px 0 0 3px;
      }

      &:hover {
        color: $white;
        background-color: $primaryBlue;
      }
    }

    .xmx-btn-current-month {
      text-transform: capitalize;
    }

    .xmx-table-date {
      thead th {
        text-transform: capitalize;
      }
    }

    .xmx-calendar-header-label {
      display: flex;
      justify-content: center;

      .xmx-btn-current-year {
        order: 2;
      }

      .xmx-btn-current-month {
        order: 1;
      }

      button {
        font-weight: 400;
        text-transform: uppercase;
        font-size: 16px;
      }
    }
  }
}
</style>
